require('css/clients/sessions/new');
import $ from 'jquery'
import 'jquery-validation/dist/jquery.validate'
import 'jquery-validation/dist/additional-methods'

$(document).ready(function(){
	$('.client-sign-in-form').validate({
		rules:{
	    'client[login]': {
	      required: true,
	    },
	    'client[password]': {
	    	required: true,
	    }
	  },
	  errorElement: "div",
	  errorPlacement: function(error, element) {
	  	$(element).parents('.input-div').append(error);
	  	$(error).addClass('badge bg-danger');
		},
		 messages: {
	    'client[login]': {
	     	required: 'Login is required.',
	    },
	    'client[password]': {
	    	required: 'Password is required.'
	    }
	  }
	});

})